const locationsInfo = [
  {
    officeType: "Head Office",
    address: "Holding No:04, Cross Road No 17, Mango, Jamshedpur, 832110",
    contact: "123-456-7890",
  },
  {
    officeType: "Branch Office",
    address:
      "Ground Floor, D-9, Desk No 45, Noida, Sector 3, Noida, Gautambuddha Nagar, Uttar Pradesh, 201301",
    contact: "123-456-7890",
  },
];

const contactUsInfo = {
  CONTACT_US_NAME: "Vinit Agarwal",
  CONTACT_US_PHONE: "+91 8789371012",
  CONTACT_US_EMAIL: "info@example.com",
};

const footerInfo = {
  FOOTER_ADDRESS: "Holding No:04, Cross Road No 17, Mango, Jamshedpur, 832110",
  FOOTER_EMAIL: "info@example.com",
  FOOTER_PHONE: "+91 8789371012",
  FOOTER_FB: "#",
  FOOTER_INSTA: "#",
  FOOTER_TWITTER: "#",
  FOOTER_LINKEDIN: "#",
};

export { locationsInfo, contactUsInfo, footerInfo };
